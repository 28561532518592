import { PageNotFound } from 'component-library'
import Seo from '../components/seo'
import { PageNotFoundContent } from 'shared-content'
import '../scss/pages/404.scss'

const { meta_title, meta_description, meta_image } = PageNotFoundContent

const NotFoundPage = () => {
  return <PageNotFound />
}

export default NotFoundPage

export const Head = () => {
  return (
    <Seo title={meta_title} description={meta_description} image={meta_image} />
  )
}
